<template>
  <div>
    <b-row>
      <b-col md="6">
        <div class="card">
          <div class="h4 font-bolder text-premier asdasd px-2 pt-2">
            สรุปรายวัน
          </div>

          <div class="mt-n75 px-2 pb-1">
            <small class="text-muted">
              ยอดผู้เล่นจะอัพเดททุก 1:00 AM (ตี 1) ของวันถัดไป
            </small>
          </div>

          <div class="d-block d-md-none px-1 py-25">
            <div
              v-for="(item, index) in reportDay"
              :key="index"
              class="card-mobile"
            >
              <div class="d-flex align-items-center justify-content-between">
                <span class="card__actv">
                  ผู้เล่น {{ item.active }}
                </span>

                <small class="text-black">
                  {{ fmDate(item.created_at) }}
                </small>
              </div>

              <div
                class="d-flex pt-50 pb-25"
                style="gap: 10px"
              >
                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    ยอดฝาก
                  </div>

                  <div class="text-premier font-weight-bolder font-medium-2">
                    {{
                      item.deposit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>

                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    ยอดถอน
                  </div>

                  <div class="text-danger font-weight-bolder font-medium-2">
                    {{
                      item.withdraw.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>

                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    กำไรสุทธิ
                  </div>
                  <div
                    :class="{
                      'text-success': item.total >= 0,
                      'text-danger': item.total < 0,
                    }"
                    class="text-premier font-weight-bolder font-medium-2"
                  >
                    {{
                      item.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive d-none d-md-block">
            <table class="table b-table w-full table-striped table-hover">
              <thead>
                <tr>
                  <th>วันที่</th>
                  <th>ยอดผู้เล่น</th>
                  <th>ยอดฝาก</th>
                  <th>ยอดถอน</th>
                  <th>กำไรสุทธิ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in reportDay"
                  :key="index"
                >
                  <td>{{ item.day + '-' + item.month + '-' + item.year }}</td>
                  <td>{{ item.active }}</td>
                  <td>
                    {{
                      item.deposit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </td>
                  <td>
                    {{
                      item.withdraw.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </td>
                  <td
                    :class="{
                      'text-success': item.total >= 0,
                      'text-danger': item.total < 0,
                    }"
                  >
                    {{
                      item.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot style="background-color: #f3f2f7">
                <tr>
                  <td colspan="4">
                    กำไรสุทธิ
                  </td>
                  <td
                    class="font-weight-bold"
                    :class="{
                      'text-success': profitDays >= 0,
                      'text-danger': profitDays < 0,
                    }"
                  >
                    {{
                      profitDays.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>

      <b-col md="6">
        <div class="card">
          <div class="h4 font-bolder text-premier asdasd p-2">
            สรุปรายเดือน
          </div>

          <div class="d-block d-md-none px-1 py-25">
            <div
              v-for="(monthData, year) in reportYear"
              :key="year"
              class="card-mobile"
            >
              <div class="d-flex align-items-center justify-content-between">
                <!-- <span class="card__actv">
                  ผู้เล่น {{ item.active }}
                </span> -->

                <small class="text-black">
                  {{ year }}
                </small>
              </div>

              <div
                class="d-flex pt-50 pb-25"
                style="gap: 10px"
              >
                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    ยอดฝาก
                  </div>

                  <div class="text-premier font-weight-bolder font-medium-2">
                    {{
                      getTotalSum(monthData, "deposit").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </div>
                </div>

                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    ยอดถอน
                  </div>

                  <div class="text-danger font-weight-bolder font-medium-2">
                    {{
                      getTotalSum(monthData, "withdraw").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </div>
                </div>

                <div class="item__f1 w-3h p-50">
                  <div class="text-muted small">
                    กำไรสุทธิ
                  </div>
                  <div
                    :class="getNumberClass(getTotalSum(monthData, 'total'))"
                    class="text-premier font-weight-bolder font-medium-2"
                  >
                    {{
                      getTotalSum(monthData, "total").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive d-none d-md-block">
            <table class="table b-table w-full table-striped table-hover">
              <thead>
                <tr>
                  <th>วันที่</th>
                  <th>ยอดฝาก</th>
                  <th>ยอดถอน</th>
                  <th>กำไรสุทธิ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(monthData, year) in reportYear"
                  :key="year"
                >
                  <td>{{ year }}</td>
                  <td>
                    {{
                      getTotalSum(monthData, "deposit").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </td>
                  <td>
                    {{
                      getTotalSum(monthData, "withdraw").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </td>
                  <td
                    :class="getNumberClass(getTotalSum(monthData, 'total'))"
                    style="font-weight: 700"
                  >
                    {{
                      getTotalSum(monthData, "total").toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      reportDay: [],
      reportYear: [],
      profitDays: '',
      fields: [
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'active', label: 'ยอดผู้เล่น' },
        { key: 'deposit', label: 'ยอดฝาก' },
        { key: 'withdraw', label: 'ยอดถอน' },
        { key: 'total', label: 'กำไรสุทธิ' },
        { key: 'profitDay', label: 'กำไรสุทธิ' },
      ],
    }
  },
  mounted() {
    this.getDay()
  },
  methods: {
    getDay() {
      this.$http
        .get('dashboard/ReportDaysAndYears')
        .then(response => {
          this.reportDay = response.data.days
          this.profitDays = response.data.profitDay
          console.log(response.data.days)
          this.reportYear = response.data.years
          // console.log(this.reportYear)
        })
        .catch(error => console.log(error))
    },
    getTotalSum(monthData, field) {
      return monthData.reduce((total, item) => total + item[field], 0)
    },
    getTotalSumForAllMonths(field) {
      let totalSum = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.reportYear) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.reportYear.hasOwnProperty(key)) {
          totalSum += this.getTotalSum(this.reportYear[key], field)
        }
      }
      return totalSum
    },
    fmDate(value) {
      return moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    getNumberClass(number) {
      return {
        'text-success': number > 0,
        'text-danger': number < 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
